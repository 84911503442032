<template>
  <div class="content">
    <div class="content-title">
      {{ detail.noticeTitle
      }}
      <router-link v-if="detail.trainId && detail.noticeClass===1" :to="`/TC-apply-detail/${detail.trainId}`"> 查看培训  </router-link>
      <!-- TC-apply-detail/2 -->
      <span v-if="detail.noticeSource " class="sTime">来源：{{ detail.noticeSource }}</span>
      <span class="sTime">发布时间：{{ detail.releaseTime }}</span>
    </div>
    <div class="content-text">
      <video v-if="detail.videoUrl" width="100%" class="videoBox" :src="loadUrl(detail.videoUrl)"  controls="controls">您的浏览器不支持 video 标签。</video>
      <div class="ql-editor" v-html="detail.noticeContent"></div>
      <a :href="loadUrl(detail.fileUrl)">{{ detail.fileName}}</a>
    </div>
  </div>
</template>


<script>
import { getNoticeDetail } from "@/api/index";
export default {
  name: "news-detail",
  props: {
    resData: Array,
    id: String,
  },
  data() {
    return {
      detail: [],
    };
  },
  mounted() {
    this.getNoticeDetails();
  },
  methods: {
    getNoticeDetails() {
      let id = this.id;
      getNoticeDetail({ id }).then((res) => {
        if (res.code == 200) {
          this.detail = res.data;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
#app .content {
  padding-left: 16px;
  padding-right: 16px;
  .content-title {
    text-align: left;
  }
  .videoBox{
    width: 100%;
    padding: 16px 0 ;
  }
  .sTime {
    display: block;
    text-align: left;
    padding-top: 10px;
    color: #999;
    font-size: 14px;
  }
 
}
</style>