import request from '@/util/request'

export const getNoticeList = data => {
    return request({
        url: '/app/notice/getNoticeList',
        method: 'post',
        data
    })
}

/**
 */
export const getNoticeDetail = params => {
    return request({
        url: '/app/notice/getNoticeDetail',
        method: 'get',
        params
    })
}
